import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useState, useEffect } from 'react'
import createDecorator from 'final-form-focus'
import HomePage from './pages/home'
import LoginPage from './pages/login'

const focusOnError = createDecorator()

export const App = () => {
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage('')
    }, 3000)

    return () => {}
  }, [errorMessage])

  return (
    <Router>
      <Routes>
        <Route path='/login' element={<LoginPage focusOnError={focusOnError} />} />
        <Route
          path='/'
          element={ <HomePage errorMessage={errorMessage} setErrorMessage={setErrorMessage} focusOnError={focusOnError} /> }
        />
      </Routes>
    </Router>
  )
}

export default App
