import styled from 'styled-components'

export const MobileMessageTableContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  overflow: hidden;
  @media (min-width: 954px) {
    display: none;
  }
`

export const TableRowContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  overflow: visible;
  border: 1px solid #d4d4d8 /* {"name":"Gray 300"} */;
  cursor: pointer;
`

export const MessageText = styled.div`
  width: 100%; /* 112px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-bottom: 5px;
`
export const MessageDataContainer = styled.div`
  width: 100%; /* 325px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
`
export const GroupsContainer = styled.div`
  width: 30px; /* 23px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-wrap: reverse-wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  margin-right: 20px;
`
export const PeopleContainer = styled.div`
width: 20px;
`
export const RecipientsContainer = styled.div`
  width: auto; /* 23px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  margin-right: 20px;
`
export const TimestampContainer = styled.div`
  width: auto; /* 23px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  margin-right: 20px;
`
export const DataText = styled.div`
  width: auto; /* 86px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
`

export const CancelMessage = styled.div`
  width: auto; /* 78px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #7c3aed /* {"name":"Violet 600"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
  cursor: pointer;
  padding:5px;
  &:hover {
    color: red;
  }
`
