import styled from 'styled-components'

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: rgba(161, 161, 170, 0.5);
`
export const ModalBody = styled.div`
  box-sizing: border-box;
  height: auto; /* 342px */
  flex-shrink: 0;
  width: 487px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 5px 10px 0px rgba(186, 188, 195, 0.25);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e4e6ea;
  @media (max-width: 950px) {
    width: 95%;
    max-width: 487px;
  }
`
export const StyledModalHeader = styled.div`
  box-sizing: border-box;
  height: 86px; /* 86px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  overflow: visible;
`
export const ModalHeaderTopSection = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
`
export const ViewMessageText = styled.div`
  width: auto; /* 109px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #1f2937;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: center;
`
export const CancelMessageText = styled.div`
  width: auto; /* 78px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #7c3aed /* {"name":"Violet 600"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
  cursor: pointer;
  padding:5px;
  &:hover {
    color: red;
  }
`
export const ModalHeaderBottomSection = styled.div`
  width: auto; /* 206px */
  height: auto; /* 12px */
  padding-top: 20px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`
export const GroupsDataContainer = styled.div`
  width: auto; /* 61px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`
export const GroupsDataText = styled.div`
  width: auto; /* 44px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-left: 5px;
  margin-right: 10px;
`
export const RecipientsDataContainer = styled.div`
  width: auto; /* 23px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`
export const RecipientsDataText = styled.div`
  width: auto; /* 6px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-left: 5px;
  margin-right: 10px;
`
export const TimestampDataContainer = styled.div`
  width: auto; /* 102px */
  height: auto; /* 12px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`
export const TimestampDataText = styled.div`
  width: auto; /* 85px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-left: 5px;
`
export const ContentContainer = styled.div`
  box-sizing: border-box;
  height: auto; /* 196px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #f5f6fa;
  overflow: visible;
`
export const SubjectContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const SubjectTitle = styled.div`
  width: auto; /* 42px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #1f2937;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
`
export const SubjectText = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
  margin-top: 10px;
`
export const MessageContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const MessageTitle = styled.div`
  width: auto; /* 51px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #1f2937;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
  margin-top: 20px;
`
export const MessageText = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: 60px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
  margin-top: 10px;
`
export const CloseButton = styled.button`
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  flex-shrink: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 26px 107px 26px 107px;
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #e4e6ea;
  &:hover {
    background-color: #7c3aed;
  }
`
export const CloseButtonText = styled.div`
  width: auto; /* 38px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: center;
`
export const ErrorMessage = styled.div`
  background-color: pink;
  width: 100%;
  text-align: center;
  color: red;
  padding:5px;
  margin-top:10px;
`
