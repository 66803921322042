import { Users } from 'phosphor-react'
import { GroupsContainer, DataText } from '../../../styles/home/mobile-message-table.styles.'
import { GroupsDataProps } from '../../../types/shared-props'

const GroupsData = ({ groupNames }: GroupsDataProps) => {

  return (
    <GroupsContainer>
      {groupNames ? (
        <>
          <DataText>
          <Users weight='duotone' color='rgb(102, 102, 255)' />
            <span> {groupNames[0]} </span>
          </DataText>
        </>
      ) : null}
    </GroupsContainer>
  )
}

export default GroupsData
