import { Field } from 'react-final-form'
import { FormService } from '../../../services/Form'
import { MessageContainer, InputLabel, MessageTextArea, MessageInfoContainer, LeftInfo, LeftInfoLeftText, LeftInfoRightText, RightInfo, RightInfoLeftText, RightInfoDotContainer, ErrorMessage, } from '../../../styles/home/form-modal.styles'
import { MessageInputProps } from '../../../types/shared-props'

const MessageInput = ({ formValues, creditsRemaining, userGroups }: MessageInputProps) => {

  const formService = new FormService()

  const charCount = formService.getCharCount(formValues)
  const totalMessageCount = Math.ceil(charCount / 130)
  const totalCredits = formService.getTotalCredits(formValues, userGroups)
  const subjectLength = formValues.subject ? formValues.subject.length : 0;
  const maxLength = formValues.multiple ? 130 - subjectLength : (130 * 3) - subjectLength;

  return (
    <Field<string> name='message' placeholder='Type your message' validate={(value) => formService.messageValidation(value, formValues, charCount, userGroups, totalCredits, creditsRemaining)} >
      {({ input, meta, placeholder }) => (
        <MessageContainer>
          <InputLabel htmlFor='message'>Message</InputLabel>
          <MessageTextArea {...input} placeholder={placeholder} className={meta.active ? 'active' : ''} maxLength={maxLength} />
          <MessageInfoContainer>
            <LeftInfo>
              <LeftInfoLeftText>{charCount}/130</LeftInfoLeftText>
              <LeftInfoRightText>{totalMessageCount} {totalMessageCount === 1 ? 'message' : 'messages'}</LeftInfoRightText>
            </LeftInfo>
            <RightInfo>
              <RightInfoLeftText>Allow multiple messages</RightInfoLeftText>
              <RightInfoDotContainer>
                <Field<boolean> name='multiple' component='input' type='checkbox' />
              </RightInfoDotContainer>
            </RightInfo>
          </MessageInfoContainer>
          {meta.error && meta.touched && ( <ErrorMessage>{meta.error}</ErrorMessage>)}
        </MessageContainer>
      )}
    </Field>
  )
}

export default MessageInput
