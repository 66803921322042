import { Field } from 'react-final-form'
import { FormService } from '../../../services/Form'
import { SubjectContainer, InputLabel, StyledSubjectInput, ErrorMessage } from '../../../styles/home/form-modal.styles'

const SubjectInput = () => {

  const formService = new FormService()

  return (
    <Field<string> name='subject' placeholder='(optional)' validate={formService.subjectValidation}>
      {({ input, meta, placeholder }) => (
        <SubjectContainer>
          <InputLabel htmlFor='subject'>Subject</InputLabel>
          <StyledSubjectInput
            {...input}
            placeholder={placeholder}
            autoComplete='off'
            maxLength={13}
            className={meta.active ? 'active' : ''}
          />
          {meta.error && meta.touched && (
            <ErrorMessage>{meta.error}</ErrorMessage>
          )}
        </SubjectContainer>
      )}
    </Field>
  )
}

export default SubjectInput
