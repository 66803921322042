import { FormService } from '../../../services/Form'
import { StyledFormHeader, CreateMessageText, CreditsText, BadCreditsText } from '../../../styles/home/form-modal.styles'
import { FormHeaderProps } from '../../../types/shared-props'

const FormHeader = ({ formValues, creditsRemaining, userGroups }: FormHeaderProps) => {

  const formService = new FormService()

  const totalCredits = formService.getTotalCredits(formValues, userGroups)

  const goodCredits = <CreditsText>{totalCredits} {totalCredits === 1 ? 'Credit' : 'Credits'}</CreditsText> 

  const badCredits = <BadCreditsText>{totalCredits} {totalCredits === 1 ? 'Credit' : 'Credits'}</BadCreditsText> 

  return (
    <StyledFormHeader>
      <CreateMessageText>Create message</CreateMessageText>
      {totalCredits <= creditsRemaining ? goodCredits : badCredits}
    </StyledFormHeader>
  )
}

export default FormHeader
