import { useState } from 'react';

export const useToken = () => {
    const [csrf_token, setTokenInternal] = useState(() => {
        return localStorage.getItem('csrf_token');
    });
    
  const setToken = (newToken:any) => {
        localStorage.setItem('csrf_token', newToken);
        setTokenInternal(newToken);
    }

    return [csrf_token, setToken];
}
