import { FormValues, SelectValue, UserSpecificGroup } from "../types"

export class FormService {
  groupsValidation (value: SelectValue[]) {
    if (!value) return 'Required'
    return undefined
  }

  subjectValidation (value: string) {
    const subjectLength = value?.length || 0
    if (subjectLength > 13) return '13 subject characters max'
    return undefined
  }

  messageValidation (value: string, formValues: FormValues, charCount: number, userGroups: UserSpecificGroup[] | undefined, totalCredits: number, creditsRemaining: number) {
    let totalContactCount = 0
    const allowMultiple = formValues.multiple
    const selectedGroups = formValues.groups?.map((group:any) => group.value) || []
    const selectedGroupsData = userGroups?.filter((userGroup:any) => selectedGroups.includes(userGroup.name)) 
    selectedGroupsData?.forEach((selectedGroup:any) => totalContactCount += selectedGroup.contactCount)

    if (!value) return 'Required'
    if (totalCredits > creditsRemaining) return 'Not enough credits'
    if (!allowMultiple && charCount > 130) return '130 character max per message'

    return undefined
  }

  required (value: string) {
    return (value ? undefined : 'Required')
  } 

  getCharCount (formValues: FormValues) {
    const messageLength = formValues?.message?.length || 0
    const subjectLength =formValues?.subject?.length || 0
    const charCount = messageLength + subjectLength

    return charCount
  }

  getTotalCredits (formValues: FormValues, userGroups: UserSpecificGroup[] | undefined) {
    const messageLength = formValues?.message?.length || 0
    const subjectLength =formValues?.subject?.length || 0
    const charCount = messageLength + subjectLength
    const totalMessageCount = Math.ceil(charCount / 130)

    let totalContactCount = 0

    const selectedGroups = formValues.groups?.map((group:any) => group.value) || []
    const selectedGroupsData = userGroups?.filter((userGroup:any) => selectedGroups.includes(userGroup.name)) 
    selectedGroupsData?.forEach((selectedGroup:any) => totalContactCount += selectedGroup.contactCount)

    const totalCredits = totalMessageCount * totalContactCount

    return totalCredits
  }

  getOptions (userGroups: UserSpecificGroup[] | undefined) {
    const options = userGroups?.map((group: any) => {
      return { value: group.name, label: group.name }
    })

    return options
  }
}
