import { Clock } from "phosphor-react";
import { TimestampContainer, DataText } from "../../../styles/home/mobile-message-table.styles.";
import { TimestampDataProps } from "../../../types/shared-props";
import { MessageService } from "../../../services/Message";

const TimestampData = ({ createdAt }: TimestampDataProps) => {

  const messageService = new MessageService()

  return (
    <TimestampContainer>
      <Clock weight="duotone" color="rgb(102, 102, 255)" />
      <DataText> {messageService.mobileNormalizeDate(createdAt)}</DataText>
    </TimestampContainer>
  );
};
export default TimestampData;
