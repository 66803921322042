import {
  StyledTableHeader,
  TableHeaderName,
  TableHeaderGroup,
  TableHeaderTimestamp,
  TableHeaderRecipients,
} from '../../../styles/home/desktop-message-table-header.styles'

export const TableHeader = () => {
  return (
    <StyledTableHeader>
        <TableHeaderName>Message</TableHeaderName>
        <TableHeaderGroup>Groups</TableHeaderGroup>
        <TableHeaderRecipients>Recipients</TableHeaderRecipients>
        <TableHeaderTimestamp>Timestamp</TableHeaderTimestamp>
    </StyledTableHeader>
  )
}
