import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { ScheduleContainer, InputLabel } from '../../../styles/home/form-modal.styles'
import { ScheduleInputProps } from '../../../types/shared-props'

const ScheduleInput = ({ selectedDate, setDate }: ScheduleInputProps) => {

  return (
    <ScheduleContainer>
      <InputLabel htmlFor='schedule'>Schedule</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              disablePast
              value={selectedDate}
              onChange={(newValue: any) => {setDate(newValue)}}
              renderInput={(props: any) => <TextField {...props} />}
            />
          </LocalizationProvider>
    </ScheduleContainer>
  )
}

export default ScheduleInput
