import styled from "styled-components";

export const MessageTableContainer = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 88%;
  height: 91%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 0px 30px 0px;
  max-width: 1280;
  overflow: visible;
  @media (max-width: 953px) {
    display: none;
  }
`;
export const TableRowContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  /*
  flex-shrink: 0;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 20px 10px 20px;
  background-color: #ffffff;
  overflow: visible;
  border: 1px solid #d4d4d8 /* {"name":"Gray 300"} ;
  cursor: pointer;
*/
`;

export const TableItem = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  overflow: visible;
  border: 1px solid #d4d4d8 /* {"name":"Gray 300"} */;
  cursor: pointer;
`;
export const DataContainer = styled.div`
  width: auto; /* 820px */
  height: auto; /* 16px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  cursor: pointer;
`;
export const MessageText = styled.div`
  height: auto; /* 16px */
  flex-shrink: 0;
  width: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (min-width: 1200px) {
    width: 300px;
  }
  @media (min-width: 1600px) {
    width: 400px;
  }
`;
export const MobileMessageText = styled.div`
  width: 100%; /* 112px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-bottom: 5px;
`;
export const GroupsContainer = styled.div`
  height: auto; /* 12px */
  flex-shrink: 0;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  @media (min-width: 1400px) {
    width: 200px;
  }
  @media (min-width: 1600px) {
    width: 250px;
  }
`;
export const GroupsText = styled.div`
  width: auto; /* 44px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
`;
export const RecipientsContainer = styled.div`
  height: auto; /* 12px */
  flex-shrink: 0;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  @media (min-width: 1400px) {
    width: 200px;
  }
  @media (min-width: 1600px) {
    width: 250px;
  }
`;
export const RecipientsText = styled.div`
  width: auto; /* 6px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
`;
export const TimestampContainer = styled.div`
  height: auto; /* 12px */
  flex-shrink: 0;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  @media (min-width: 1400px) {
    width: 200px;
  }
  @media (min-width: 1600px) {
    width: 250px;
  }
`;

export const TimestampText = styled.div`
  width: auto; /* 85px */
  height: auto; /* 12px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

export const CancelMessage = styled.div`
  width: auto; /* 78px */
  height: auto; /* 12px */
  margin-left: auto;
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #7c3aed /* {"name":"Violet 600"} */;
  font-size: 10px;
  letter-spacing: 0px;
  line-height: 1.2;
  cursor: pointer;
  padding: 5px;
  &:hover {
    color: red;
  }
`;
