import { useQuery } from 'react-query'
import axios from 'axios'
import { useUserId } from './useUserId'
import { AXIOS_CONFIG } from '../constants'
import { UserSpecificGroup } from '../types'



export const useUserGroups = () => {

  const userId = useUserId()

  // get the specific group data needed from api endpoint
  const filterUserGroups = (responseData: any /* data from axios response */) => {
    const groups: UserSpecificGroup[] = [];
    responseData.data.included.forEach((group: any) => {
      if (group.type === 'node--group') {
        groups.push({name: group.attributes.title, contactCount: group.attributes.field_group_contact_count});
      }
    })
    return groups
  }

  const getUserGroups = (id: string) => axios.get(`/jsonapi/user/user/${id}?include=field_groups`, AXIOS_CONFIG)

  const userGroups = useQuery('groups', () => getUserGroups(userId), {
    enabled: !!userId,
    select: (data) => filterUserGroups(data),
  }).data

  return userGroups
}
