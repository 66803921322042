import styled from "styled-components";

export const LoginScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color:  #fafafa;
  overflow: hidden;
`;
export const StyledHeader = styled.header`
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 0px;
  background-color: #ffffff;
  overflow: visible;
  border-color: #d4d4d8 /* {"name":"Gray 300"} */;
  border-style: solid;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
`;

export const HeaderContent = styled.div`
  height: auto; /* 24px */
  flex-shrink: 0;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280;
  overflow: visible;
`;

export const UserInfo = styled.div`
  width: auto; /* 118px */
  height: auto; /* 24px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`;

export const AlertSendText = styled.div`
  width: auto; /* 84px */
  height: auto; /* 22px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

export const LoginText = styled.div`
  width: auto; /* 40px */
  height: auto; /* 16px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

export const MainContent = styled.div`
  margin-top: 100px;
  width: 100%;
  flex: 1;
  height: 1px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  overflow: visible;
`;
export const FormContainer = styled.div`
  height: auto; /* 266px */
  flex-shrink: 0;
  width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1280;
  overflow: visible;
`;
export const LogoContainer = styled.div`
  margin-bottom: 20px;
  width: auto; /* 118px */
  height: auto; /* 24px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`;

export const LogoText = styled.div`
  width: auto; /* 84px */
  height: auto; /* 22px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

export const StyledForm = styled.form`
  width: auto; /* 400px */
  height: auto; /* 192px */
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`;
export const TextField = styled.div`
  height: auto; /* 56px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 400;
  overflow: visible;
`;
export const TextFieldLabel = styled.label`
  width: 100%; /* 67px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: "Inter", sans-serif;
  color: #1f2937;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
`;
export const InputContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto; /* 32px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`;
export const StyledInput = styled.input`
  box-sizing: border-box;
  height: auto; /* 32px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #a1a1aa /* {"name":"Gray 400"} */;
  ::placeholder {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow: visible;
    width: auto; /* 56px */
    height: auto; /* 12px */
    flex-shrink: 0;
    white-space: pre;
    overflow: visible;
    font-weight: 400;
    font-style: normal;
    font-family: "Inter", sans-serif;
    color: #d4d4d8 /* {"name":"Gray 300"} */;
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 100%;
    text-align: left;
  }
`;
export const LoginButton = styled.button`
  margin-top: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 400px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 20px;
  background-color: #7c3aed;
  max-width: 400;
  overflow: hidden;
  border-radius: 5px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const LoginButtonText = styled.div`
  width: auto; /* 40px */
  height: auto; /* 16px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 300;
  font-family: "Inter-Light", "Inter", sans-serif;
  color: #ffffff /* {"name":"White"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
`;
export const ErrorDiv = styled.div`
  margin: 10px;
  width: 100%;
  text-align: center;
  color: red;
`;
