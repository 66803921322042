import axios from 'axios'
import { useQuery } from 'react-query'
import { AXIOS_CONFIG } from '../constants'
import { MessageType } from '../types'
import { AppGroup } from '../types'


export const useMessagesAndGroups = () => {

    const uid = JSON.parse(localStorage.getItem('current_user') || '{}').uid

    const messageEndpoint = `/jsonapi/node/message?filter[uid.uid][value]=${uid}&include=field_message_groups&sort=-created`

    const getMessages = (messagesEndpoint: string) => axios.get(messagesEndpoint, AXIOS_CONFIG)

    const filterAppGroups = (responseData: any /* cannot type data from axios response */) => {
    const group: AppGroup = { id: responseData.id, title: responseData.attributes.title, contactCount: responseData.attributes.field_group_contact_count }
      return group
    }

    const filterMessageData = (responseObject: any /* cannot type data from axios response */) => {
      const message: MessageType = {
          id : responseObject.id,
          subject : responseObject.attributes.field_message_subject,
          message : responseObject.attributes.title,
          groups : responseObject.relationships.field_message_groups.data,
          recipients : +responseObject.attributes.field_message_recipients_count,
          createdAt : responseObject.attributes.created
      }
      return message
    }

  const { isLoading, data } = useQuery( 'messages', () => getMessages(messageEndpoint), { enabled: !!messageEndpoint })

  const messages: MessageType[] = data?.data?.data?.map((responseObject: any) => filterMessageData(responseObject))

  const groups: AppGroup[] = data?.data?.included?.map((i: any) => filterAppGroups(i))

  return { isLoading, messages, groups }

}
