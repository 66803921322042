import { RecipientsContainer, RecipientsText } from '../../../styles/home/desktop-message-table.styles'
import { Phone } from 'phosphor-react'
import { RecipientDataProps } from '../../../types/shared-props'

const RecipientData = ({ recipients }: RecipientDataProps ) => {
  return (
    <RecipientsContainer>
      <Phone weight="duotone" color="rgb(102, 102, 255)" />
      <RecipientsText>{recipients}</RecipientsText>
    </RecipientsContainer>
  )
}

export default RecipientData
