import { useQueryClient } from "react-query";
import {
  MessageDataContainer,
  MessageText,
  CancelMessage,
} from "../../../styles/home/mobile-message-table.styles.";
import GroupsData from "./GroupsData";
import RecipientData from "./RecipientData";
import TimestampData from "./TimestampData";
import { MessageService } from "../../../services/Message";
import { MessageType, AppGroup } from '../../../types'

interface TableRowProps {
  message: MessageType
  groups: AppGroup[]
}

export const TableRow = ({ message, groups  }: TableRowProps) => {
  const queryClient = useQueryClient();
  const subject = message?.subject;
  const messageService = new MessageService();
  const truncatedMessage = messageService.truncate(message?.message || '');
  const groupNames = messageService.getGroupNames(message, groups, 2);

  return (
    <>
      <MessageText>{subject || truncatedMessage}</MessageText>
      <MessageDataContainer>
        <GroupsData groupNames={groupNames} />
        <RecipientData recipients={message.recipients} />
        <TimestampData createdAt={message.createdAt * 1000} />
        {messageService.checkStatus(message.createdAt * 1000) === "pending" && (
          <CancelMessage onClick={() => messageService.cancelMessage( queryClient, message.id,  message) } >
            {" "}
            Cancel{" "}
          </CancelMessage>
        )}
      </MessageDataContainer>
    </>
  );
};
