import styled from "styled-components";
export const HomeScreen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  overflow: hidden;
`;
export const MainContent = styled.div`
  width: 100%;
  flex: 1;
  height: 1px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  overflow: visible;
`;
export const ErrorMessage = styled.div`
  background-color: pink;
  width: 100%;
  text-align: center;
  color: red;
  padding: 5px;
  margin-top: 10px;
`;
export const LogoutErrorMessage = styled.h1`
  background-color: pink;
  width: 100%;
  text-align: center;
  color: red;
  padding: 5px;
  margin-top: 10px;
`;
export const LoadingMessage = styled.h1`
  width: 100%;
  text-align: center;
  color: #7c3aed;
  padding: 5px;
  margin-top: 10px;
`;

export const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    paddingLeft: 5,
  }),
};
