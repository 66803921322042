import { useQuery } from 'react-query'
import { useUserId } from './useUserId'
import axios from 'axios'
import { AXIOS_CONFIG } from '../constants'


export const useCredits = () => {

  const userId = useUserId()

  const getUserCredits = (id: string) => axios.get(`/jsonapi/user/user/${id}`, AXIOS_CONFIG)

  const credits = useQuery('credits', () => getUserCredits(userId), {
    enabled: !!userId,
    select: (data) => data?.data?.data.attributes.field_messages_count,
  }).data

  return credits
}
