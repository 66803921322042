import * as React from "react";
import { Plus, SignOut, Megaphone } from "phosphor-react";
import { useNavigate } from "react-router-dom";
import { UserService } from "../../services/User";
import {
  StyledHeader,
  HeaderContent,
  LeftHeaderSection,
  MessagesText,
  RightHeaderSection,
  CreditsText,
  CreateButton,
  CreateMessageText,
} from "../../styles/home/header.styles";

interface HomeHeaderProps {
  credits: number;
  setShouldShow: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

export const Header = (props: HomeHeaderProps) => {
  const navigate = useNavigate();
  const userService = new UserService(navigate, props.setErrorMessage);

  return (
    <>
      <StyledHeader>
        <HeaderContent>
          <LeftHeaderSection>
            <MessagesText> Messages</MessagesText>
            <Megaphone color="rgb(124, 58, 237)" mirrored={true} weight="duotone" />
          </LeftHeaderSection>
          <RightHeaderSection>
            <CreditsText>
              {props.credits} Credits{"  "}
            </CreditsText>
            <CreateButton onClick={() => props.setShouldShow(true)}>
              <Plus color="rgb(255, 255, 255)" weight='regular' />
              <CreateMessageText>Create message</CreateMessageText>
            </CreateButton>
            <SignOut onClick={() => userService.logout(navigate)} style={{ cursor: "pointer" }} weight="bold" color="rgb(102, 102, 255)" />
          </RightHeaderSection>
        </HeaderContent>
      </StyledHeader>
    </>
  );
};
