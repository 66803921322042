import { useState, useEffect} from 'react';
import { useToken } from './useToken';

export const useUser = () => {
    const [csrf_token] = useToken();


    const [user, setUser] = useState(() => {
        if (!csrf_token) return null; 
        return csrf_token
    });

    useEffect(() => {
        if (!csrf_token) {
            setUser(null);
        } else {
            setUser(csrf_token);
        }
        return () => {}
    }, [csrf_token]);

    return user;
}
