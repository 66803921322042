import { Field } from 'react-final-form'
import { FormService } from '../../services/Form'
import { TextField, TextFieldLabel, InputContainer, StyledInput, ErrorDiv } from '../../styles/login/login.styles'

export const UsernameInput = () => {

  const formService = new FormService()

  return (
      <Field<string> name='name' placeholder='username' validate={formService.required}>
        {({ input, meta, placeholder }) => (
          <TextField>
            <TextFieldLabel>
              {' '}
              Username
              <InputContainer>
                <StyledInput {...input} placeholder={placeholder} className={meta.active ? 'active' : ''} />
              </InputContainer>
            </TextFieldLabel>
            {meta.error && meta.touched && (
              <ErrorDiv>{meta.error}</ErrorDiv>
            )}
          </TextField>
        )}
      </Field>
  )
}
