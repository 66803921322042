import { CloseButton, CloseButtonText } from '../../../styles/home/view-message-modal.styles'
import { ModalFooterProps } from '../../../types/shared-props'

const ModalFooter = ({setViewMessage}: ModalFooterProps) => {
  return (
    <CloseButton onClick={() => setViewMessage({show: false, message: null})}>
      <CloseButtonText>Close</CloseButtonText>
    </CloseButton>
  )
}

export default ModalFooter
