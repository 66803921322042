import * as React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getUnixTime } from 'date-fns'
import { QueryClient, UseMutationResult } from 'react-query'
import { AppGroup, MessageType, UserSpecificGroup } from '../types'

export class MessageService {
  async cancelMessage(
    queryClient: QueryClient,
    messageId: string,
    message: MessageType
  ) {
    const toastId = toast.loading('Canceling Message...', {
      position: 'top-center',
    })
    const csrf_token = localStorage.getItem('csrf_token')
    const cancelUrl = `/jsonapi/node/message/${messageId}`
    const postUrl = '/api/marcom/messages?_format=json'
    const axiosConfig: any /* can't get TS to stop complaining */ = {
      headers: { 'X-CSRF-Token': csrf_token },
      withCredentials: true,
    }

    const messageLength = message?.message?.length || 0
    const subjectLength = message?.subject?.length || 0
    const charCount = messageLength + subjectLength
    const totalMessageCount = Math.ceil(charCount / 130)

    try {
      await axios.delete(cancelUrl, axiosConfig)
      if (typeof message?.recipients === 'number') {
        const returnCredits = message?.recipients * totalMessageCount
        await axios.post(postUrl, { returnCredits: returnCredits }, axiosConfig)
        toast.update(toastId, {
          render: 'Message Cancelled',
          type: 'success',
          isLoading: false,
        })

        setTimeout(() => {
          toast.dismiss(toastId)
        }, 5000)
      }
      await queryClient.invalidateQueries(['messages'])
      await queryClient.invalidateQueries(['credits'])
    } catch (error) {
      toast.update(toastId, {
        render: 'error canceling message',
        type: 'error',
        isLoading: false,
      })
    }
  }

  getGroupNames(message: MessageType, groups: AppGroup[], num: number) {
    let groupNames = message?.groups?.map((groupObj: any) => {
      const groupId = groupObj?.id
      const correctGroup = groups?.filter((obj: any) => obj.id === groupId)
      const groupName = correctGroup && correctGroup.length ? correctGroup[0].title : ""
      return groupName
    })
    if (groupNames?.length > num) {
      groupNames = groupNames.slice(0, num)
    }
    return groupNames
  }

  async submitMessage(
    values: any /* TS keeps complaining */,
    userGroups: UserSpecificGroup[] | undefined,
    createMessage: UseMutationResult,
    selectedDate: Date | null,
    queryClient: QueryClient,
    setShouldShow: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    const toastId = toast.loading('Creating Message...', {
      position: 'top-center',
    })

    const messageLength = values?.message?.length || 0
    const subjectLength = values?.subject?.length || 0
    const charCount = messageLength + subjectLength
    const totalMessageCount = Math.ceil(charCount / 130)

    let totalContactCount = 0
    const selectedGroups = values.groups?.map((group: any) => group.value) || []
    const selectedGroupsData = userGroups?.filter((userGroup: any) =>
      selectedGroups.includes(userGroup.name)
    )

    selectedGroupsData?.forEach(
      (selectedGroup: any) => (totalContactCount += selectedGroup.contactCount)
    )

    const totalCredits = totalMessageCount * totalContactCount

    let stampToSend = new Date()
    if (selectedDate) stampToSend = selectedDate

    const StampToSend = getUnixTime(stampToSend)
    const PhoneNumbers: string[] = []
    const Groups = values.groups.map((obj: any) => obj.value)
    const Subject = values.subject
    const Message = values.message
    const messageToSend = {
      PhoneNumbers,
      Groups,
      Subject,
      Message,
      StampToSend,
      totalCredits,
    }

    createMessage.mutate(messageToSend, {
      onSuccess: (data: any) => {
        if (
          data?.data?.message &&
          data?.data?.message === 'insufficient credits'
        ) {
          toast.update(toastId, {
            render: 'insufficient credits',
            type: 'error',
            isLoading: false,
          })
          setTimeout(() => {
            toast.dismiss(toastId)
          }, 5000)
        } else {
          queryClient.invalidateQueries(['credits'])
          toast.update(toastId, {
            render: 'Message Created',
            type: 'success',
            isLoading: false,
          })
          setTimeout(() => {
            toast.dismiss(toastId)
            setShouldShow(false)
          }, 5000)
        }
      },
      onError: () => {
        toast.update(toastId, {
          render: 'error creating message',
          type: 'error',
          isLoading: false,
        })
        setTimeout(() => {
          toast.dismiss(toastId)
        }, 5000)
      },
    })

    await queryClient.invalidateQueries(['messages'])
  }

  normalizeDate(epochDate: number) {
    var date = new Date(epochDate)
    return date.toLocaleString()
  }

  mobileNormalizeDate(epochDate: number) {
    var date = new Date(epochDate)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDay()
    const formattedDate = `${month}/${day}/${year}`
    return formattedDate
  }

  truncate(input: string) {
    return input?.length > 20 ? `${input.substring(0, 20)}...` : input
  }

  checkStatus(time: number) {
    const current = new Date().getTime()
    if (time > current) {
      return 'pending'
    } else {
      return 'sent'
    }
  }
}
