import axios, { AxiosRequestConfig } from 'axios'
import { NavigateFunction } from 'react-router-dom'
import { AuthEndpointsType, UseUserType, LoginInfoType, SetErrorMessageType, SetTokenType} from '../types' 



export class UserService {
  config: AxiosRequestConfig<any> =  { headers: { 'Content-type': 'application/json' }, withCredentials: true };
  login_url:  AuthEndpointsType = '/user/login?_format=json';
  logout_url:  AuthEndpointsType = '/user/logout?_format=json';
  jsonapi: AuthEndpointsType = '/jsonapi'
  navigate: NavigateFunction;
  setErrorMessage: SetErrorMessageType

  constructor(navigate: NavigateFunction, setErrorMessage: SetErrorMessageType) { 
    this.navigate = navigate
    this.setErrorMessage = setErrorMessage
  }


  async login(user: UseUserType, values: LoginInfoType, setToken: SetTokenType) {
    try {
      if (user) {
        this.navigate('/', { replace: true })
        return
      }
      const response = await axios.post(this.login_url, values, this.config)
      const { csrf_token, current_user, logout_token } = response.data
      typeof setToken === 'function' && setToken(csrf_token)

      localStorage.setItem('current_user', JSON.stringify(current_user))
      localStorage.setItem('logout_token', logout_token)
      this.navigate('/', { replace: true })

    } catch (error: any) {
      const status = error?.response?.status || ''
      if (status && status === 403) {
        await axios.get('/user/logout', this.config)
        this.login(user, values, setToken)
      } else if (status && status === 400) {
        this.setErrorMessage('Unauthorized')
      } else {
        this.setErrorMessage('Sign in error')
      }
    }
  }

  async logout(navigate: NavigateFunction) {
    try {
      await axios.get(this.logout_url, this.config)
      localStorage.clear()
      navigate('/login', { replace: true })
    } catch (error) {
      this.setErrorMessage('Error logging out')
    }
  }
}
