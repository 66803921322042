import React from "react";
import { MobileMessageTableContainer, TableRowContainer } from "../../../styles/home/mobile-message-table.styles.";
import { TableRow } from "./TableRow";
import { AppGroup, MessageType, ViewMessageState } from "../../../types";

interface MessageTableProps {
  messages: MessageType[];
  groups: AppGroup[];
  setViewMessage: React.Dispatch<React.SetStateAction<ViewMessageState>>;
}

export const MobileMessageTable = ({
  messages,
  groups,
  setViewMessage,
}: MessageTableProps) => {
  return (
    <MobileMessageTableContainer>
      {React.Children.toArray(
        messages?.map((message: any, i: any) => (
          <TableRowContainer
            key={i}
            onClick={() => setViewMessage({ show: true, message })}
          >
            <TableRow
              message={message}
              groups={groups}
            />
          </TableRowContainer>
        ))
      )}{" "}
    </MobileMessageTableContainer>
  );
};
