import { Field } from 'react-final-form'
import { FormService } from '../../services/Form'
import { TextField, TextFieldLabel, InputContainer, StyledInput, ErrorDiv } from '../../styles/login/login.styles'

export const PasswordInput = () => {

  const formService = new FormService()

  return (
    <TextField>
        <Field<string> name='pass' placeholder='password' validate={formService.required}>
          {({ input, meta, placeholder }) => (
            <>
              <TextFieldLabel>
                {' '}
                Password
                <InputContainer>
                  <StyledInput className={meta.active ? 'active' : ''} {...input} placeholder={placeholder} type="password"/>
                </InputContainer>
              </TextFieldLabel>
              {meta.error && meta.touched && ( <ErrorDiv>{meta.error}</ErrorDiv>)}
            </>
          )}
        </Field>
      </TextField>
  )
}
