import { Megaphone } from "phosphor-react";
import {
  StyledHeader,
  HeaderContent,
  UserInfo,
  AlertSendText,
  LoginText,
} from "../../styles/login/login.styles";

export const Header = () => {
  return (
    <StyledHeader>
      <HeaderContent>
        <UserInfo>
          <AlertSendText>AlertSend </AlertSendText>
          <Megaphone color="rgb(124, 58, 237)" mirrored={true} weight="duotone" />
        </UserInfo>
        <LoginText>Log In</LoginText>
      </HeaderContent>
    </StyledHeader>
  );
};
