import {
  ContentContainer,
  SubjectContainer,
  SubjectTitle,
  SubjectText,
  MessageContainer,
  MessageTitle,
  MessageText,
} from '../../../styles/home/view-message-modal.styles'

import { ModalContentProps } from '../../../types/shared-props'

const ModalContent = ({ message }: ModalContentProps) => {
  return (
    <ContentContainer>
      {message?.subject && (
        <SubjectContainer>
          <SubjectTitle>Subject</SubjectTitle>
          <SubjectText>{message.subject}</SubjectText>
        </SubjectContainer>
      )}
      <MessageContainer>
        <MessageTitle>Message</MessageTitle>
        <MessageText>{message?.message}</MessageText>
      </MessageContainer>
    </ContentContainer>
  )
}
export default ModalContent
