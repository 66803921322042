import { ModalBackground, ModalBody, ErrorMessage } from "../../../styles/home/view-message-modal.styles";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalFooter from "./ModalFooter";
import { ViewMessageModalProps } from "../../../types/shared-props";


export const ViewMessageModal = ({ viewMessage, setViewMessage, groups, cancelError }: ViewMessageModalProps) => {

  return (
    <>
      {viewMessage.show && (
        <ModalBackground onClick={() => setViewMessage({ show: false, message: null })} >
          <ModalBody onClick={(e: any) => e.stopPropagation()}>
            {viewMessage.message &&<ModalHeader message={viewMessage.message} groups={groups}/> }
            {cancelError && <ErrorMessage>{cancelError}</ErrorMessage>}
            <ModalContent message={viewMessage.message}/>
            <ModalFooter setViewMessage={setViewMessage}/>
          </ModalBody>
        </ModalBackground>
      )}
    </>
  );
};
