import { useState, useEffect } from "react";
import { useToken } from "../hooks/useToken";
import { useUser } from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import { Megaphone } from "phosphor-react";
import { Header } from "../components/login/Header";
import {
  LoginScreen,
  MainContent,
  FormContainer,
  LogoContainer,
  LogoText,
  ErrorDiv,
  StyledForm,
  LoginButton,
  LoginButtonText,
} from "../styles/login/login.styles";
import { LoginInfoType } from "../types";
import { UsernameInput } from "../components/login/UsernameInput";
import { PasswordInput } from "../components/login/PasswordInput";
import { UserService } from "../services/User";

const LoginPage = (
  { focusOnError }: any /* do not know focus on error type */
) => {
  const navigate = useNavigate();
  const user = useUser();
  const [, setToken] = useToken();
  const [errorMessage, setErrorMessage] = useState("");
  const userService = new UserService(navigate, setErrorMessage);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
    return () => {};
  }, [errorMessage]);

  const onSubmit = (values: LoginInfoType) => {
    userService.login(user, values, setToken);
  };

  return (
    <LoginScreen>
      <Header />
      <MainContent>
        <FormContainer>
          <LogoContainer>
            <LogoText> AlertSend </LogoText>
            <Megaphone color="rgb(124, 58, 237)" mirrored={true} weight="duotone" />
          </LogoContainer>
          {errorMessage && <ErrorDiv>{errorMessage}</ErrorDiv>}
          <Form
            onSubmit={onSubmit}
            decorators={[focusOnError]}
            subscription={{ submitting: true }}
          >
            {({ handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                <UsernameInput />
                <PasswordInput />
                <LoginButton type="submit">
                  <LoginButtonText>Log in</LoginButtonText>{" "}
                </LoginButton>
              </StyledForm>
            )}
          </Form>
        </FormContainer>
      </MainContent>
    </LoginScreen>
  );
};

export default LoginPage;
