import { useQuery } from 'react-query'
import axios from 'axios'
import { AXIOS_CONFIG } from '../constants'

export const useUserId = () =>  {
  const jsonapi = '/jsonapi'

  // get user id from api endpoint, to use for future requests
  const getUserId = () => axios.get(jsonapi, AXIOS_CONFIG)

  const { data, isLoading } = useQuery('user', () => getUserId())

  if (!isLoading) {
    return data?.data?.meta?.links?.me?.meta?.id
  }
}
