import { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { Form } from "react-final-form";
import { ModalBackground, StyledForm } from "../../../styles/home/form-modal.styles";
import FormHeader from "./FormHeader";
import FormButtons from "./FormButtons";
import FormInputs from "./FormInputs";
import { MessageService } from "../../../services/Message";
import { useCreateMessage } from "../../../hooks/useCreateMessage";
import { FormModalProps } from "../../../types/shared-props";

const FormModal = ({ shouldShow, setShouldShow, creditsRemaining, userGroups, selectedDate, setDate, focusOnError }: FormModalProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const queryClient = useQueryClient();
  const messageService = new MessageService()




  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
  }, [errorMessage]);

  const createMessage = useCreateMessage()

  return (
    <>
      {shouldShow && (
        <ModalBackground onClick={() => setShouldShow(false)}>
          <Form onSubmit={(values) => messageService.submitMessage( values, userGroups, createMessage, selectedDate,  queryClient, setShouldShow) } decorators={[focusOnError]} >
            {({ handleSubmit, submitting, pristine, values }) => (
              <StyledForm onSubmit={handleSubmit} onClick={(e: any) => e.stopPropagation()} >

                <FormHeader formValues={values} creditsRemaining={creditsRemaining} userGroups={userGroups} />
                <FormInputs
                  errorMessage={errorMessage}
                  selectedDate={selectedDate}
                  setDate={setDate}
                  formValues={values}
                  creditsRemaining={creditsRemaining}
                  userGroups={userGroups}
                />
                <FormButtons
                  setShouldShow={setShouldShow}
                  submitting={submitting}
                  pristine={pristine}
                  creditsRemaining={creditsRemaining}
                  formValues={values}
                  userGroups={userGroups}
                />
              </StyledForm>
            )}
          </Form>
        </ModalBackground>
      )}
    </>
  );
};

export default FormModal;
