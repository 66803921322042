import * as React from 'react'
import { MessageTableContainer } from '../../../styles/home/desktop-message-table.styles'
import { TableHeader } from './TableHeader'
import TableRow from './TableRow'
import { AppGroup, MessageType } from '../../../types'

interface MessageTableProps {
  messages: MessageType[]
  groups: AppGroup[]
  setViewMessage: React.Dispatch<React.SetStateAction<any>>
}

export const DesktopMessageTable = ({ messages, groups, setViewMessage }: MessageTableProps) => {
  return (
    <MessageTableContainer>
      <TableHeader />
      {React.Children.toArray(
        messages?.map((message: any, i: any) => (
          <TableRow
            key={i}
            message={message}
            setViewMessage={setViewMessage}
            groups={groups}
          />
        ))
      )}{' '}
    </MessageTableContainer>
  )
}
