import ScheduleInput from './ScheduleInput'
import MessageInput from './MessageInput'
import SubjectInput from './SubjectInput'
import GroupsInput from './GroupsInput'
import { InputsContainer } from '../../../styles/home/form-modal.styles'
import { ErrorMessage } from '../../../styles/home/form-modal.styles'
import { FormInputsProps } from '../../../types/shared-props'


const FormInputs = ({ errorMessage, selectedDate, setDate, formValues, creditsRemaining, userGroups }: FormInputsProps) => {

  return (
    <InputsContainer>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <GroupsInput userGroups={userGroups} />
      <ScheduleInput selectedDate={selectedDate} setDate={setDate} />
      <SubjectInput />
      <MessageInput
        formValues={formValues}
        creditsRemaining={creditsRemaining}
        userGroups={userGroups}
      />
    </InputsContainer>
  )
}

export default FormInputs
