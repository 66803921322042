import styled from 'styled-components'

export const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: fixed;
  z-index: 1;
`
export const StyledForm = styled.form`
  box-sizing: border-box;
  height: auto; /* 540px */
  flex-shrink: 0;
  width: 487px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0px 5px 10px 0px rgba(186, 188, 195, 0.25);
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e4e6ea;
  @media (max-width: 950px) {
    width: 95%;
    max-width: 487px;
  }
`
export const StyledFormHeader = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  overflow: visible;
`
export const CreateMessageText = styled.div`
  width: auto; /* 109px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #1f2937;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: center;
`
export const CreditsText = styled.div`
  width: auto; /* 67px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #13ae2d;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: center;
`
export const BadCreditsText = styled.div`
  width: auto; /* 67px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: red;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: center;
`

export const InputsContainer = styled.div`
  box-sizing: border-box;
  height: auto; /* 454px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #f5f6fa;
  overflow: visible;
`

export const GroupsContainer = styled.div`
  height: auto; /* 68px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const SelectStyles = styled.div`
  width: 100%;
  height: 44px;
`
export const ScheduleContainer = styled.div`
  height: auto; /* 58px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const SubjectContainer = styled.div`
  height: auto; /* 56px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const SubjectInputContainer = styled.div`
  height: auto; /* 32px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const StyledSubjectInput = styled.input`
  box-sizing: border-box;
  height: auto; /* 32px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d1d5db;
`
export const MessageContainer = styled.div`
  margin: 10px;
  height: auto; /* 118px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const MessageTextArea = styled.textarea`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d1d5db;
`
export const MessageInfoContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  flex-shrink: 0;
  height: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
`
export const LeftInfo = styled.div`
  width: auto; /* 77px */
  height: auto; /* 8px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`

export const LeftInfoLeftText = styled.div`
  width: auto; /* 22px */
  height: auto; /* 8px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
  margin-left: 5px;
`
export const LeftInfoRightText = styled.div`
  width: auto; /* 45px */
  height: auto; /* 8px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
  margin-left: 10px;
`
export const RightInfo = styled.div`
  width: auto; /* 108px */
  height: auto; /* 10px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`
export const RightInfoLeftText = styled.div`
  width: auto; /* 93px */
  height: auto; /* 8px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #71717a /* {"name":"Gray 500"} */;
  font-size: 8px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: right;
`
export const RightInfoDotContainer = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 1px;
  border: 1px solid #d4d4d8 /* {"name":"Gray 300"} */;
  color: green;
  background-color: green;
  margin-left: 5px;
  margin-right: 3px;
`
export const RightInfoDot = styled.div`
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  background-color: #7c3aed;
  overflow: visible;
  aspect-ratio: 1 / 1;
  border-radius: 1px;
`

export const ButtonsContainer = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: visible;
`
export const CancelButton = styled.button`
  box-sizing: border-box;
  flex: 1;
  width: 1px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 44px 8px 44px;
  overflow: hidden;
  border: 1px solid #e4e6ea;
  &:hover {
    background-color: #e75c4a;
  }
`
export const CancelText = styled.div`
  width: auto; /* 46px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
`
export const ConfirmButton = styled.button`
  box-sizing: border-box;
  flex: 1;
  width: 1px;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 44px 8px 44px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #e4e6ea;
  &:hover {
    background-color: #7c3aed;
  }
`
export const ConfirmText = styled.div`
  width: auto; /* 53px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 500;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: center;
`
export const InputContainer = styled.div`
  margin: auto 0;
  height: auto; /* 68px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const InputLabel = styled.label`
  width: auto; /* 40px */
  height: auto; /* 14px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 400;
  font-style: normal;
  font-family: 'Inter', sans-serif;
  color: #1f2937;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const StyledInputContainer = styled.div`
  height: 28px; /* 32px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
`
export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 30px; /* 32px */
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d1d5db;
`
export const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #d1d5db;
`
export const ErrorMessage = styled.div`
  background-color: pink;
  width: 100%;
  text-align: center;
  color: red;
  padding:5px;
  margin-top:10px;
`
