import React from 'react'
import { Users } from 'phosphor-react'
import { GroupsContainer, GroupsText } from '../../../styles/home/desktop-message-table.styles'
import { GroupsDataProps } from '../../../types/shared-props'

const GroupsData = ({ groupNames }: GroupsDataProps) => {
  if(groupNames && groupNames.length > 2) {
    groupNames = groupNames.slice( 0, 2 )
  }

  return (
    <GroupsContainer>
      <Users weight='duotone' color='rgb(102, 102, 255)' />
      <GroupsText>
        {React.Children.toArray(
          groupNames?.map((groupName: any, i: any) => (
            <span key={i}>{groupName ? groupName : ' '} </span>
          ))
        )}{' '}
      </GroupsText>
    </GroupsContainer>
  )
}

export default GroupsData
