import { RecipientsContainer, DataText } from '../../../styles/home/mobile-message-table.styles.'
import { Phone } from 'phosphor-react'
import { RecipientDataProps } from '../../../types/shared-props'

const RecipientData = ({ recipients }: RecipientDataProps) => {
  return (
    <RecipientsContainer>
      <Phone weight="duotone" color="rgb(102, 102, 255)" />
      <DataText>{recipients}</DataText>
    </RecipientsContainer>
  )
}

export default RecipientData
