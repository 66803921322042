import React from 'react'
import { Users, Phone, Clock } from 'phosphor-react'
import { useQueryClient } from 'react-query'
import { MessageService } from '../../../services/Message'
import {
  StyledModalHeader,
  ModalHeaderTopSection,
  ViewMessageText,
  CancelMessageText,
  ModalHeaderBottomSection,
  GroupsDataContainer,
  GroupsDataText,
  RecipientsDataContainer,
  RecipientsDataText,
  TimestampDataContainer,
  TimestampDataText,
} from '../../../styles/home/view-message-modal.styles'

import { ModalHeaderProps } from '../../../types/shared-props'

const ModalHeader = ({ message, groups }: ModalHeaderProps) => {
  const messageService = new MessageService()
  const groupNames = messageService.getGroupNames(message, groups, 5)
  const queryClient = useQueryClient()

  return (
    <StyledModalHeader>
      <ModalHeaderTopSection>
        <ViewMessageText>View message</ViewMessageText>
        {message &&
          messageService.checkStatus(message.createdAt * 1000) ===
            'pending' && (
            <CancelMessageText
              onClick={() =>
                messageService.cancelMessage(
                  queryClient,
                  message.id,
                  message
                )
              }
            >
              {' '}
              Cancel message{' '}
            </CancelMessageText>
          )}
      </ModalHeaderTopSection>
      <ModalHeaderBottomSection>
        <GroupsDataContainer>
          <Users weight='duotone' color='rgb(102, 102, 255)' />
          <GroupsDataText>
            {React.Children.toArray(
              groupNames?.map((groupName: any, i: any) => (
                <span key={i}>{groupName ? groupName : ' '} </span>
              ))
            )}{' '}
          </GroupsDataText>
        </GroupsDataContainer>
        <RecipientsDataContainer>
          <Phone weight='duotone' color='rgb(102, 102, 255)' />
          <RecipientsDataText>{message?.recipients}</RecipientsDataText>
        </RecipientsDataContainer>
        <TimestampDataContainer>
          <Clock weight='duotone' color='rgb(102, 102, 255)' />
          <TimestampDataText>
            {message && messageService.normalizeDate(message.createdAt)}
          </TimestampDataText>
        </TimestampDataContainer>
      </ModalHeaderBottomSection>
    </StyledModalHeader>
  )
}

export default ModalHeader
