import { FormService } from '../../../services/Form'
import { ButtonsContainer, CancelButton, CancelText, ConfirmButton, ConfirmText, } from '../../../styles/home/form-modal.styles'
import { FormButtonsProps } from '../../../types/shared-props'

const FormButtons = ({ setShouldShow, submitting, pristine, formValues, userGroups, creditsRemaining}: FormButtonsProps) => {


  const formService = new FormService()
  const totalCredits = formService.getTotalCredits(formValues, userGroups)

  return (
    <ButtonsContainer>
      <CancelButton type='button' onClick={() => setShouldShow(false)}>
        <CancelText>Cancel</CancelText>
      </CancelButton>
      <ConfirmButton type='submit' disabled={submitting || pristine || totalCredits > creditsRemaining}>
        <ConfirmText>Confirm</ConfirmText>
      </ConfirmButton>
    </ButtonsContainer>
  )
}
export default FormButtons
