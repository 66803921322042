import styled from "styled-components";

export const StyledHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 0px;
  background-color: #ffffff;
  overflow: visible;
  border-color: #d4d4d8 /* {"name":"Gray 300"} */;
  border-style: solid;
  border-top-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
`;

export const HeaderContent = styled.div`
  height: auto; /* 40px */
  flex-shrink: 0;
  width: 88%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1280;
  overflow: visible;
`;

export const LeftHeaderSection = styled.div`
  width: auto; /* 198px */
  height: auto; /* 30px */
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
`;
export const AlertSendText = styled.div`
  width: auto; /* 46px */
  height: auto; /* 16px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (max-width: 950px) {
    display: none;
  }
`;
export const Slash = styled.div`
  width: auto; /* 5px */
  height: auto; /* 16px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (max-width: 950px) {
    display: none;
  }
`;
export const MessagesText = styled.div`
  width: auto; /* 87px */
  height: auto; /* 22px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #27272a /* {"name":"Gray 800"} */;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 1.2;
  margin-right: 5px;
`;
export const LogoutContainer = styled.div`
  box-sizing: border-box;
  width: auto; /* 170px */
  flex-shrink: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 20px;
  background-color: white;
  color: #7c3aed;
  overflow: hidden;
  border-radius: 5px;
`;

export const RightHeaderSection = styled.div`
  flex-shrink: 0;
  width: auto;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: visible;
`;

export const CreditsText = styled.div`
  width: auto; /* 148px */
  height: auto; /* 16px */
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-family: "Inter", sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

export const CreateButton = styled.button`
  box-sizing: border-box;
  width: auto; /* 170px */
  flex-shrink: 0;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 0px 20px 0px 20px;
  background-color: #7c3aed;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 15px;
  @media (max-width: 950px) {
    width: 40px;
    padding: 0px;
    overflow: hidden;
    margin-right: 10px;
  }
`;

export const CreateMessageText = styled.div`
  width: auto; /* 107px */
  height: auto; /* 16px */
  margin-top: 1px;
  flex-shrink: 0;
  white-space: pre;
  overflow: visible;
  font-weight: 300;
  font-family: "Inter-Light", "Inter", sans-serif;
  color: #ffffff /* {"name":"White"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (max-width: 950px) {
    display: none;
  }
`;
