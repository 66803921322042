import axios from 'axios'
import { useMutation } from 'react-query';
import { useToken } from "./useToken";

export const useCreateMessage = () => {
  const [csrf_token] = useToken();

  const messagesUrl = "/api/marcom/messages?_format=json";

  const postConfig: any = { headers: { "X-CSRF-Token": csrf_token }, withCredentials: true };

  const createMessage = useMutation((newMessage: any) => axios.post(messagesUrl, newMessage, postConfig));

  return createMessage
}
