import Select from 'react-select'
import { Field } from 'react-final-form'
import { GroupsContainer, InputLabel, SelectStyles, ErrorMessage } from '../../../styles/home/form-modal.styles'
import { customStyles } from '../../../styles/home/home.styles'
import { FormService } from '../../../services/Form'
import { GroupsInputProps } from '../../../types/shared-props'
import { SelectValue } from '../../../types'

const GroupsInput = ({ userGroups }: GroupsInputProps) => {

  const formService = new FormService()

  const options = formService.getOptions(userGroups)

  return (
    <Field<SelectValue[]> name='groups' validate={formService.groupsValidation}>
      {({ input, meta }) => (
        <GroupsContainer>
          <InputLabel htmlFor='groups'>Group</InputLabel>
          <SelectStyles>
            <Select options={options} styles={customStyles} isMulti {...input} />
          </SelectStyles>
          {meta.error && meta.touched && ( <ErrorMessage>{meta.error}</ErrorMessage>)}
        </GroupsContainer>
      )}
    </Field>
  )
}

export default GroupsInput
