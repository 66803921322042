import { Clock } from "phosphor-react";
import { MessageService } from "../../../services/Message";
import { TimestampContainer, TimestampText } from "../../../styles/home/desktop-message-table.styles";
import { TimestampDataProps } from "../../../types/shared-props";

const TimestampData = ({ createdAt }: TimestampDataProps) => {

  const messageService = new MessageService()

  return (
    <TimestampContainer>
      <Clock weight="duotone" color="rgb(102, 102, 255)" />
      <TimestampText> {messageService.normalizeDate(createdAt)}</TimestampText>
    </TimestampContainer>
  );
};
export default TimestampData;
