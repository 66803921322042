import styled from 'styled-components'

export const StyledTableHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 10px 20px;
  background-color: #f4f4f5;
  overflow: visible;
  border-radius: 5px 5px 0px 0px;
  border-color: #d4d4d8 /* {"name":"Gray 300"} */;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
`
export const TableHeaderName = styled.div`
  height: auto; /* 17px */
  flex-shrink: 0;
  width: 200px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (min-width: 1200px) {
    width: 300px;
  }
  @media (min-width: 1600px) {
    width: 400px;
  }
`
export const TableHeaderGroup = styled.div`
  height: auto; /* 17px */
  flex-shrink: 0;
  width: 150px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (min-width: 1400px) {
    width: 200px;
  }
  @media (min-width: 1600px) {
    width: 250px;
  }
`
export const TableHeaderRecipients = styled.div`
  height: auto; /* 17px */
  flex-shrink: 0;
  width: 150px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (min-width: 1400px) {
    width: 200px;
  }
  @media (min-width: 1600px) {
    width: 250px;
  }
`
export const TableHeaderTimestamp = styled.div`
  height: auto; /* 17px */
  flex-shrink: 0;
  width: 150px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  color: #a1a1aa /* {"name":"Gray 400"} */;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  @media (min-width: 1400px) {
    width: 200px;
  }
  @media (min-width: 1600px) {
    width: 250px;
  }
`
