import { useQueryClient } from 'react-query'
import { TableRowContainer, TableItem,  MessageText, CancelMessage } from "../../../styles/home/desktop-message-table.styles";
import GroupsData from "./GroupsData";
import RecipientData from "./RecipientData";
import TimestampData from "./TimestampData";
import { MessageService } from '../../../services/Message';
import { MessageType, AppGroup, ViewMessageState } from '../../../types'

interface TableRowProps {
  message: MessageType
  groups: AppGroup[]
  setViewMessage: React.Dispatch<React.SetStateAction<ViewMessageState>>
}

const TableRow = ({ message, setViewMessage, groups }: TableRowProps) => {

  const messageService = new MessageService()

  const groupNames = messageService.getGroupNames(message, groups, 3)

  const queryClient = useQueryClient()

  console.log(message);

  return (
  <TableRowContainer onClick={() => setViewMessage({show: true, message})} >
    <TableItem>
        <MessageText>{(message && message.subject) || (message.message && messageService.truncate(message?.message))}</MessageText>
        <GroupsData groupNames={groupNames} />
        <RecipientData recipients={message.recipients}/>
        <TimestampData createdAt={message.createdAt}/>
          {messageService.checkStatus(message.createdAt) === "pending" ? (
            <CancelMessage onClick={() => messageService.cancelMessage(queryClient, message.id, message)}> Cancel message </CancelMessage>
) : (<CancelMessage></CancelMessage>)}
    </TableItem>
  </TableRowContainer>
  );
};

export default TableRow;
