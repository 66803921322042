import * as React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Header } from '../components/home/Header'
import FormModal from '../components/home/form-modal/FormModal'
import { ViewMessageModal } from '../components/home/view-message-modal/ViewMessageModal'
import { DesktopMessageTable } from '../components/home/desktop-message-table/DesktopMessageTable'
import { MobileMessageTable } from '../components/home/mobile-message-table/MobileMessageTable'
import { HomeScreen, MainContent, LogoutErrorMessage, } from '../styles/home/home.styles'
import { useUser } from '../hooks/useUser'
import { useUserGroups } from '../hooks/useUserGroups'
import { useCredits } from '../hooks/useCredits'
import { useMessagesAndGroups } from '../hooks/useMessagesAndGroups'
import { ViewMessageState } from '../types'

interface HomePageProps {
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  focusOnError: any // do not know type of focusOnError
}

const HomePage = (props: HomePageProps) => {
  const [viewMessage, setViewMessage] = useState<ViewMessageState>({
    show: false,
    message: null,
  })
  const [shouldShow, setShouldShow] = useState(false)
  const [cancelError, setCancelError] = useState('')
  const [selectedDate, setDate] = useState<Date | null>(new Date())
  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
    setTimeout(() => {
      setCancelError('')
    }, 3000)
    return () => {}
  }, [user, navigate, cancelError])

  const userGroups = useUserGroups()
  const credits = useCredits()
  const { isLoading, messages, groups } = useMessagesAndGroups()

  if (isLoading) return <h1>Loading...</h1>
  if (props.errorMessage)
    return <LogoutErrorMessage>{props.errorMessage}</LogoutErrorMessage>

  return (
    <>
      <HomeScreen>
        <FormModal
          shouldShow={shouldShow}
          setShouldShow={setShouldShow}
          creditsRemaining={credits}
          userGroups={userGroups}
          selectedDate={selectedDate}
          setDate={setDate}
          focusOnError={props.focusOnError}
        />
        <ViewMessageModal
          viewMessage={viewMessage}
          setViewMessage={setViewMessage}
          groups={groups}
          cancelError={cancelError}
        />
        <Header
          setShouldShow={setShouldShow}
          credits={credits}
          setErrorMessage={props.setErrorMessage}
        />
        <MainContent>
          <DesktopMessageTable
            messages={messages}
            setViewMessage={setViewMessage}
            groups={groups}
          />
          <MobileMessageTable
            messages={messages}
            setViewMessage={setViewMessage}
            groups={groups}
          />
        </MainContent>
      </HomeScreen>
    </>
  )
}

export default HomePage
